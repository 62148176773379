import { defineStore, StoreDefinition } from "pinia";
import type { ListResponse } from "../ts/types/api_types";
import type { Project } from "../ts/types/project_types";
import useApiStore from "./ApiStore";

export interface ProjectState {
    projects: Array<Project>;
    isFetching: boolean;
    current: number;
}

export type ProjectGetters = any;

export interface ProjectActions {
    fetchProjects: () => Promise<void>;
}

const _fetchProjects = async (startPage: number) => {
    /**
     * fetch all Projects and update state
     *
     * @param  { number }  starting page number
     */
    const apiStore = useApiStore();
    const projectStore = useProjectStore();

    projectStore.isFetching = true;

    let page: number = startPage;
    let newProjects: Array<Project> = [];

    while (true) {
        const data: ListResponse<Project> | null = await apiStore.meteringApi
            .listProjects({
                page: page,
            })
            .catch((err: unknown) => {
                console.error(err);
                newProjects = [];
                return null;
            });

        if (!data) break;
        newProjects = newProjects.concat(data.results);
        if (!data.next) break;
        page += 1;
    }

    projectStore.isFetching = false;
    projectStore.projects = newProjects;
};

const useProjectStore: StoreDefinition<"projectStore", ProjectState, ProjectGetters, ProjectActions> = defineStore(
    /**
     * Global Store/Context for all projects of the logged in user
     * @store
     */
    "projectStore",
    {
        state: () => {
            /** fetch all projects starting with the first page */
            _fetchProjects(1);
            /** Get current project with input hidden workaround */
            const input: HTMLInputElement | null = document.querySelector("#current_project_id");
            if (!input) throw new Error("'#current_project_id' input is missing!");
            const current: number = Number(input.value);

            return {
                projects: [] as Array<Project>,
                isFetching: true,
                current,
            };
        },
        getters: {},
        actions: {
            async fetchProjects() {
                await _fetchProjects(1);
            },
        },
    }
);

export default useProjectStore;
