export const parseProjectUrl = (url: string) => {
    const segments = new URL(url).pathname.split("/");

    const projectIdx = segments.findIndex((segment) => segment === "projects");
    if (projectIdx === -1) throw new Error(`/projects/ not found in url: ${url}`);
    if (projectIdx === segments.length - 1 || !segments[projectIdx + 1])
        throw new Error(`missing project id in url: ${url}`);
    const id = parseInt(segments[projectIdx + 1]);

    return { id };
};
