import { defineStore, StoreDefinition } from "pinia";
import type { ListResponse } from "../ts/types/api_types";
import type { LevyStructure } from "../ts/types/levy_types";
import { waitUntil } from "../utils/wait";
import useApiStore from "./ApiStore";
import useProjectStore from "./ProjectStore";

export interface LevyState {
    levies: Array<LevyStructure>;
    isFetching: boolean;
}

export interface LevyGetters {
    getLevy: (state: LevyState) => (levyId: number) => Promise<LevyStructure>;
    getLevyByUrl: (_: LevyState) => (url: string) => Promise<LevyStructure>;
}

export interface LevyActions {
    fetchLevies: () => Promise<void>;
}

const _fetchLevies = async (startPage: number, projectId: number) => {
    /**
     * fetch all Levys and update state
     *
     * @param  { number }  starting page number
     * @param  { number }  id of the current project
     */
    const apiStore = useApiStore();
    const levyStore = useLevyStore();

    levyStore.isFetching = true;

    let page: number = startPage;
    let newLevies: Array<LevyStructure> = [];

    while (true) {
        const data: ListResponse<LevyStructure> | null = await apiStore.accountingApi
            .listLevyStructures({
                page,
                /* pageSize: 100, */
                projectId,
            })
            .catch((err: unknown) => {
                console.error(err);
                newLevies = [];
                return null;
            });

        if (!data) break;
        newLevies = newLevies.concat(data.results);
        if (!data.next) break;
        page += 1;
    }

    levyStore.isFetching = false;
    levyStore.levies = newLevies;
};

const useLevyStore: StoreDefinition<"levyStore", LevyState, LevyGetters, LevyActions> = defineStore(
    /**
     * Global Store/Context for all levys of the logged in user
     * @store
     */
    "levyStore",
    {
        state: () => {
            const projectStore = useProjectStore();

            /** fetch all levys starting with the first page */
            _fetchLevies(1, projectStore.current);

            return {
                levies: [] as Array<LevyStructure>,
                isFetching: true,
            };
        },
        getters: {
            getLevy(state: LevyState) {
                return async (levyId: number) => {
                    await waitUntil(() => !this.isFetching);
                    const levy: LevyStructure | undefined = state.levies.filter(
                        (levy: LevyStructure) => levy.id === levyId
                    )[0];
                    if (!levy) throw new Error(`No levy found with the id ${levyId}`);
                    return levy;
                };
            },
            getLevyByUrl(_: LevyState) {
                return async (url: string) => {
                    const regexp = /api\/v1\/levies\/\d+\/$/;
                    const snippetStart: number | undefined = url.match(regexp)?.index;
                    if (!snippetStart) throw new Error("Regex did not match field.");
                    const snippet: string = url.substr(snippetStart);
                    const id: number = Number(snippet.split("/")[3]);
                    const getLevy: (id: number) => Promise<LevyStructure> = this.getLevy;
                    const levy: LevyStructure = await getLevy(id);
                    return levy;
                };
            },
        },
        actions: {
            async fetchLevies() {
                const projectStore = useProjectStore();
                await _fetchLevies(1, projectStore.current);
            },
        },
    }
);

export default useLevyStore;
