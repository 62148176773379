import { defineStore, StoreDefinition } from "pinia";
import type { ListResponse } from "../ts/types/api_types";
import type { Invoice } from "../ts/types/invoice_types";
import useApiStore from "./ApiStore";
import useProjectStore from "./ProjectStore";

export interface InvoiceState {
    invoices: Array<Invoice>;
    isFetching: boolean;
}

// TODO getInvoice && getInvoiceByUrl
export type InvoiceGetters = any;

export interface InvoiceActions {
    fetchInvoices: () => Promise<void>;
}

const _fetchInvoices = async (startPage: number, projectId: number) => {
    /**
     * fetch all Invoices and update state
     *
     * @param  { number }  starting page number
     * @param  { number }  id of the current project
     */
    const apiStore = useApiStore();
    const invoiceStore = useInvoiceStore();

    invoiceStore.isFetching = true;

    let page: number = startPage;
    let newInvoices: Array<Invoice> = [];

    while (true) {
        const data: ListResponse<Invoice> | null = await apiStore.accountingApi
            .listInvoices({
                page,
                pageSize: 100,
                projectId,
            })
            .catch((err: unknown) => {
                console.error(err);
                newInvoices = [];
                return null;
            });

        if (!data) break;
        newInvoices = newInvoices.concat(data.results);
        if (!data.next) break;
        page += 1;
    }

    invoiceStore.isFetching = false;
    invoiceStore.invoices = newInvoices;
};

const useInvoiceStore: StoreDefinition<"invoiceStore", InvoiceState, InvoiceGetters, InvoiceActions> = defineStore(
    /**
     * Global Store/Context for all invoices of the logged in user
     * @store
     */
    "invoiceStore",
    {
        state: () => {
            const projectStore = useProjectStore();

            /** fetch all invoices starting with the first page */
            _fetchInvoices(1, projectStore.current);

            return {
                invoices: [] as Array<Invoice>,
                isFetching: true,
            };
        },
        getters: {},
        actions: {
            async fetchInvoices() {
                const projectStore = useProjectStore();
                await _fetchInvoices(1, projectStore.current);
            },
        },
    }
);

export default useInvoiceStore;
