import { BootstrapVue } from "bootstrap-vue";
import { createPinia } from "pinia";
import PortalVue from "portal-vue";
import { App, configureCompat, createApp } from "vue";
import { createI18n } from "vue-i18n";
import AccountingTable from "./components/AccountingTable.vue";
import CustomerTable from "./components/CustomerTable.vue";
import DashboardNotifications from "./components/DashboardNotifications.vue";
import DashboardStatistics from "./components/DashboardStatistics.vue";
import ExporterButtons from "./components/ExporterButtons.vue";
import GlobalSearch from "./components/GlobalSearch.vue";
import MeasuringPointExporterButton from "./components/MeasuringPointExporterButton.vue";
import DocumentCreator from "./components/documentcreator/DocumentCreator.vue";
import PreBilling from "./components/prebilling/PreBilling.vue";
import ProjectSetupWorkflow from "./components/workflows/projectsetup/ProjectSetupWorkflow.vue";

/** Do not print any warning coming from BV in production builds */
if (process.env.NODE_ENV === "production") {
    const originalConsoleWarn = console.warn;
    const wrappedConsoleWarn = (...args) => {
        const is_bv: boolean = args[0].includes("[BootstrapVue warn]:");
        if (!is_bv) return originalConsoleWarn(...args);
    };
    console.warn = wrappedConsoleWarn;
}

configureCompat({
    GLOBAL_EXTEND: true,
    MODE: 2,
    WATCH_ARRAY: true,
});

const pinia = createPinia();

const i18n = createI18n({
    legacy: false,
    locale: "de",
    fallbackLocale: "en",
    messages: {
        en: {
            customer: "Customer",
            entries_per_page: "Entries per page",
            search: "Search ...",
            loading: "Loading ...",
            tableError: "An error has ocurred",
            zeroFilteredRecords: "There are no records matching your request",
            zeroRecords: "There are no records to show",
            create_now: "Create now",
        },
        de: {
            customer: "Kunde",
            entries_per_page: "Einträge pro Seite",
            search: "Suche ...",
            loading: "Lädt ...",
            tableError: "Ein Fehler ist aufgetreten",
            zeroFilteredRecords: "Für den Filter konnten keine Einträge gefunden werden",
            zeroRecords: "Keine Einträge vorhanden",
            create_now: "Jetzt anlegen",
        },
    },
});

const buildApp = (): App => {
    return createApp({}).use(pinia).use(i18n).use(PortalVue);
};

buildApp()
    .use(BootstrapVue) // Can only be used once
    .component("CustomerTable", CustomerTable)
    .component("AccountingTable", AccountingTable)
    .component("DocumentCreator", DocumentCreator)
    .component("PreBilling", PreBilling)
    .component("ProjectSetupWorkflow", ProjectSetupWorkflow)
    .component("ExporterButtons", ExporterButtons)
    .component("DashboardStatistics", DashboardStatistics)
    .component("MeasuringPointExporterButton", MeasuringPointExporterButton)
    .mount("#vapp");

buildApp().component("GlobalSearch", GlobalSearch).mount("#header-vapp");
buildApp().component("DashboardStatistics", DashboardStatistics).mount("#dashboard-statistics-vapp");
buildApp().component("DashboardNotifications", DashboardNotifications).mount("#dashboard-notifications-vapp");
