import axios, { AxiosResponse } from "axios";
import { defineStore, StoreDefinition } from "pinia";
import type { ListResponse } from "../ts/types/api_types";
import type { Customer } from "../ts/types/customer_types";
import type { Invoice } from "../ts/types/invoice_types";
import type { Subscription } from "../ts/types/subscription_types";
import type { Transaction } from "../ts/types/transaction_types";
import { waitUntil } from "../utils/wait";
import useApiStore from "./ApiStore";
import useInvoiceStore from "./InvoiceStore";
import useProjectStore from "./ProjectStore";
import useSubscriptionStore from "./SubscriptionStore";
import useTransactionStore from "./TransactionStore";

export interface CustomerState {
    customers: Array<Customer>;
    isFetching: boolean;
}

export type CustomerGetters = {
    getCustomer: (state: CustomerState) => (customerId: number) => Promise<Customer>;
    getCustomerByUrl: (_: CustomerState) => (url: string) => Promise<Customer>;
    getCustomerBySubscription: (_: CustomerState) => (subscriptionId: number) => Promise<Customer>;
    getCustomerBySubscriptionUrl: (_: CustomerState) => (url: string) => Promise<Customer>;
    getSubscriptions: (_: CustomerState) => (customerId: number) => Promise<Array<Subscription>>;
    getSubscription: (_: CustomerState) => (customerId: number) => Promise<Subscription>;
    getInvoices: (_: CustomerState) => (customerId: number) => Promise<Array<Invoice>>;
    getTransactions: (_: CustomerState) => (customerId: number) => Promise<Array<Transaction>>;
};

export interface CustomerActions {
    fetchCustomers: () => Promise<void>;
}

const _fetchCustomers = async (startPage: number, projectId: number) => {
    /**
     * fetch all Customers and update state
     *
     * @param  { number }  starting page number
     * @param  { number }  id of the current project
     */
    const apiStore = useApiStore();
    const customerStore = useCustomerStore();
    const projectStore = useProjectStore();

    customerStore.isFetching = true;

    let page: number = startPage;
    let newCustomers: Array<Customer> = [];

    while (true) {
        const response: AxiosResponse<ListResponse<Customer>> = await axios.get(
            apiStore.defaultClient.basePath + `/api/v1/customers`,
            {
                params: {
                    project__id: projectStore.current,
                    page,
                },
            }
        );
        const data = response.data;

        if (!data) break;
        newCustomers = newCustomers.concat(data.results);
        if (!data.next) break;
        page += 1;
    }

    customerStore.isFetching = false;
    customerStore.customers = newCustomers;
};

const useCustomerStore: StoreDefinition<"customerStore", CustomerState, CustomerGetters, CustomerActions> = defineStore(
    /**
     * Global Store/Context for all costumers of the logged in user
     * @store
     */
    "customerStore",
    {
        state: () => {
            const projectStore = useProjectStore();

            /** fetch all customers starting with the first page */
            _fetchCustomers(1, projectStore.current);

            return {
                customers: [],
                isFetching: true,
            } as CustomerState;
        },
        getters: {
            getCustomer(state: CustomerState) {
                return async (customerId: number) => {
                    await waitUntil(() => !this.isFetching);
                    const customer: Customer | undefined = state.customers.filter(
                        (customer: Customer) => customer.id === customerId
                    )[0];
                    if (!customer) throw new Error(`No customer found with the id ${customerId}`);
                    return customer;
                };
            },
            getCustomerByUrl(_: CustomerState) {
                return async (url: string) => {
                    const regexp = /api\/v1\/customers\/\d+\/$/;
                    const snippetStart: number | undefined = url.match(regexp)?.index;
                    if (!snippetStart) throw new Error("Regex did not match field.");
                    const snippet: string = url.substr(snippetStart);
                    const id: number = Number(snippet.split("/")[3]);
                    const customer: Customer = await this.getCustomer(id);
                    return customer;
                };
            },
            getCustomerBySubscription(_: CustomerState) {
                return async (subscriptionId: number) => {
                    const subscriptionStore = useSubscriptionStore();
                    await waitUntil(() => !this.isFetching);
                    await waitUntil(() => !subscriptionStore.isFetching);
                    const subscription = await subscriptionStore.getSubscription(subscriptionId);
                    const customer: Customer = await this.getCustomerByUrl(subscription.customer);
                    return customer;
                };
            },
            getCustomerBySubscriptionUrl(_: CustomerState) {
                return async (url: string) => {
                    const subscriptionStore = useSubscriptionStore();
                    const subscription = await subscriptionStore.getSubscriptionByUrl(url);
                    const customer = await this.getCustomerBySubscription(subscription.id);
                    return customer;
                };
            },
            getSubscriptions(_: CustomerState) {
                return async (customerId: number) => {
                    const subscriptionStore = useSubscriptionStore();
                    await waitUntil(() => !subscriptionStore.isFetching);
                    const subscriptions: Array<Subscription> = [];
                    for (const subscription of subscriptionStore.subscriptions) {
                        if (subscription.customer.includes(`/${customerId}/`)) {
                            subscriptions.push(subscription);
                        }
                    }
                    // TODO throw Error if no subscription
                    return subscriptions;
                };
            },
            getSubscription(_: CustomerState) {
                return async (customerId: number) => {
                    const subscriptions = await this.getSubscriptions(customerId);
                    return subscriptions[0];
                };
            },
            getInvoices(_: CustomerState) {
                return async (customerId: number) => {
                    const invoiceStore = useInvoiceStore();
                    await waitUntil(() => !invoiceStore.isFetching);
                    const invoices: Array<Invoice> = [];
                    for (const invoice of invoiceStore.invoices) {
                        if (invoice.state == "issued" && invoice.customer.includes(`/${customerId}/`)) {
                            invoices.push(invoice);
                        }
                    }

                    return invoices;
                };
            },
            getTransactions(_: CustomerState) {
                return async (customerId: number) => {
                    const transactionStore = useTransactionStore();
                    await waitUntil(() => !transactionStore.isFetching);
                    const transactions: Array<Transaction> = [];
                    for (const transaction of transactionStore.transactions) {
                        if (transaction.customer?.includes(`/${customerId}/`)) {
                            transactions.push(transaction);
                        }
                    }

                    return transactions;
                };
            },
        },
        actions: {
            async fetchCustomers() {
                const projectStore = useProjectStore();
                await _fetchCustomers(1, projectStore.current);
            },
        },
    }
);

export default useCustomerStore;
