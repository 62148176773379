export enum NotificationVariant {
    Primary = "primary",
    Secondary = "secondary",
    Danger = "danger",
    Warning = "warning",
    Success = "success",
    Info = "info",
    Default = "default",
}

export interface NotificationHolderInstance {
    setVisible: (t?: boolean) => void;
    setVariant: (v: NotificationVariant) => void;
    setMessage: (m: string) => void;
    createToast: (v: NotificationVariant, m: string) => void;
    createSuccessToast: (m: string) => void;
    createDangerToast: (m: string) => void;
}
