import * as metergrid from "@metergrid/sdk-js";
import { defineStore, StoreDefinition } from "pinia";
import type { AccountingApi, DefaultClient, JwtAuth, MeteringApi, TokenApi } from "../ts/types/api_types";

export interface ApiState {
    defaultClient: DefaultClient;
    jwtAuth: JwtAuth;
    tokenApi: TokenApi;
    accountingApi: AccountingApi;
    meteringApi: MeteringApi;
    accessToken: string | null;
    refreshToken: string | null;
}

const useApiStore: StoreDefinition<"apiStore", ApiState, any, any> = defineStore("apiStore", {
    state: () => {
        const defaultClient = metergrid.ApiClient.instance;
        const csrfTokenInput: HTMLInputElement | null = document.querySelector("#csrf_token");

        if (!csrfTokenInput) throw new Error("'#csrf_token' input is missing!");
        defaultClient.defaultHeaders["X-CSRFToken"] = csrfTokenInput.value;

        defaultClient.basePath = process.env.HOST!;

        const jwtAuth = defaultClient.authentications.jwtAuth;

        const accessToken: string | null = localStorage.getItem("access_token");
        const refreshToken: string | null = localStorage.getItem("refresh_token");

        if (accessToken) jwtAuth.accessToken = accessToken;
        if (refreshToken) jwtAuth.refreshToken = refreshToken;

        const tokenApi = new metergrid.TokenApi(defaultClient);
        const accountingApi = new metergrid.AccountingApi(defaultClient);
        const meteringApi = new metergrid.MeteringApi(defaultClient);

        return {
            defaultClient,
            jwtAuth,
            tokenApi,
            accountingApi,
            meteringApi,
            accessToken,
            refreshToken,
        };
    },
});

export default useApiStore;
