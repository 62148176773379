import { defineStore, StoreDefinition } from "pinia";
import type { ListResponse } from "../ts/types/api_types";
import type { MeasuringPoint } from "../ts/types/measuringpoint_types";
import { waitUntil } from "../utils/wait";
import useApiStore from "./ApiStore";
import useProjectStore from "./ProjectStore";

export interface MeasuringPointState {
    measuringPoints: Array<MeasuringPoint>;
    isFetching: boolean;
}

export interface MeasuringPointGetters {
    getMeasuringPoint: (state: MeasuringPointState) => (measuringPointId: number) => Promise<MeasuringPoint>;
    getMeasuringPointByUrl: (_: MeasuringPointState) => (url: string) => Promise<MeasuringPoint>;
    getMeasuringPointsByPanelId: (state: MeasuringPointState) => (panelId: number) => Promise<Array<MeasuringPoint>>;
    getMeasuringPointsByPanelUrl: (_: MeasuringPointState) => (url: string) => Promise<Array<MeasuringPoint>>;
}

export interface MeasuringPointActions {
    fetchMeasuringPoints: () => Promise<void>;
}

const _fetchMeasuringPoints = async (startPage: number, projectId: number) => {
    /**
     * fetch all MeasuringPoints and update state
     *
     * @param  { number }  starting page number
     * @param  { number }  id of the current project
     */
    const apiStore = useApiStore();
    const measuringPointStore = useMeasuringPointStore();

    measuringPointStore.isFetching = true;

    let page: number = startPage;
    let newMeasuringPoints: Array<MeasuringPoint> = [];

    while (true) {
        const data: ListResponse<MeasuringPoint> | null = await apiStore.meteringApi
            .listMeasuringPoints({
                page,
                pageSize: 100,
                projectId,
            })
            .catch((err: unknown) => {
                console.error(err);
                newMeasuringPoints = [];
                return null;
            });

        if (!data) break;
        newMeasuringPoints = newMeasuringPoints.concat(data.results);
        if (!data.next) break;
        page += 1;
    }

    measuringPointStore.isFetching = false;
    measuringPointStore.measuringPoints = newMeasuringPoints;
};

const useMeasuringPointStore: StoreDefinition<
    "measuringPointStore",
    MeasuringPointState,
    MeasuringPointGetters,
    MeasuringPointActions
> = defineStore(
    /**
     * Global Store/Context for all measuringPoints of the logged in user
     * @store
     */
    "measuringPointStore",
    {
        state: () => {
            const projectStore = useProjectStore();

            /** fetch all measuringPoints starting with the first page */
            _fetchMeasuringPoints(1, projectStore.current);

            return {
                measuringPoints: [] as Array<MeasuringPoint>,
                isFetching: true,
            };
        },
        getters: {
            getMeasuringPoint(state: MeasuringPointState) {
                return async (measuringPointId: number) => {
                    await waitUntil(() => !this.isFetching);
                    const measuringPoint: MeasuringPoint | undefined = state.measuringPoints.filter(
                        (mp: MeasuringPoint) => mp.id === measuringPointId
                    )[0];
                    if (!measuringPoint) throw new Error(`No measuring point found with the id ${measuringPointId}`);
                    return measuringPoint;
                };
            },
            getMeasuringPointByUrl(_: MeasuringPointState) {
                return async (url: string) => {
                    const regexp = /api\/v1\/measuringpoints\/\d+\/$/;
                    const snippetStart: number | undefined = url.match(regexp)?.index;
                    if (!snippetStart) throw new Error("Regex did not match field.");
                    const snippet: string = url.substr(snippetStart);
                    const id: number = Number(snippet.split("/")[3]);
                    const getMeasuringPoint: (id: number) => Promise<MeasuringPoint> = this.getMeasuringPoint;
                    const measuringpoint: MeasuringPoint = await getMeasuringPoint(id);
                    return measuringpoint;
                };
            },
            getMeasuringPointsByPanelId(state: MeasuringPointState) {
                return async (panelId: number) => {
                    const measuringPoints: Array<MeasuringPoint> = state.measuringPoints
                        .map((m) => {
                            const panelUrl: string = m.panel!;
                            const regexp = /api\/v1\/panels\/\d+\/$/;
                            const snippetStart: number | undefined = panelUrl.match(regexp)?.index;
                            if (!snippetStart) throw new Error("Regex did not match field.");
                            const snippet: string = panelUrl.substr(snippetStart);
                            const id: number = Number(snippet.split("/")[3]);
                            if (panelId === id) return m;
                        })
                        .filter((m) => m !== undefined) as Array<MeasuringPoint>;
                    return measuringPoints;
                };
            },
            getMeasuringPointsByPanelUrl(state: MeasuringPointState) {
                return async (url: string) => {
                    const measuringPoints: Array<MeasuringPoint> = state.measuringPoints
                        .map((m) => {
                            if (url === m.panel) return m;
                        })
                        .filter((m) => m !== undefined) as Array<MeasuringPoint>;
                    return measuringPoints;
                };
            },
        },
        actions: {
            async fetchMeasuringPoints() {
                const projectStore = useProjectStore();
                await _fetchMeasuringPoints(1, projectStore.current);
            },
        },
    }
);

export default useMeasuringPointStore;
