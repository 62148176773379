import { defineStore, StoreDefinition } from "pinia";
import type { SepaMandate } from "../ts/types/mandate_types";
import useApiStore from "./ApiStore";
import useProjectStore from "./ProjectStore";

export interface MandateState {
    mandates: Array<SepaMandate>;
    isFetching: boolean;
}

// TODO getMandate && getMandateByUrl
export type MandateGetters = any;

export interface MandateActions {
    fetchMandates: () => void;
}

const fetchMandates = (page: number) => {
    /**
     * fetchMandates recursively adds all Mandates to the state
     *
     * @param  { number }  starting page number
     */
    const apiStore = useApiStore();
    const mandateStore = useMandateStore();
    const projectStore = useProjectStore();

    apiStore.accountingApi
        .listSepaMandates({
            page,
            pageSize: 100,
            projectId: projectStore.current,
        })
        .then((data: { count: number; next: string | null; previous: string | null; results: Array<SepaMandate> }) => {
            mandateStore.isFetching = true;
            mandateStore.mandates = mandateStore.mandates.concat(data.results);
            if (data.next) return fetchMandates(page + 1);
            mandateStore.isFetching = false;
        })
        .catch((err: unknown) => {
            console.error(err);
            mandateStore.mandates = [] as Array<SepaMandate>;
        });
};

const useMandateStore: StoreDefinition<"mandateStore", MandateState, MandateGetters, MandateActions> = defineStore(
    /**
     * Global Store/Context for all mandates of the logged in user
     * @store
     */
    "mandateStore",
    {
        state: () => {
            /** fetch all mandates starting with the first page */
            fetchMandates(1);

            return {
                mandates: [] as Array<SepaMandate>,
                isFetching: true,
            };
        },
        getters: {},
        actions: {
            fetchMandates() {
                this.mandates = [];
                fetchMandates(1);
            },
        },
    }
);

export default useMandateStore;
