import { defineStore, StoreDefinition } from "pinia";
import type { ListResponse } from "../ts/types/api_types";
import type { MeasuringPointPanel } from "../ts/types/measuringpoint_types";
import { waitUntil } from "../utils/wait";
import useApiStore from "./ApiStore";
import useProjectStore from "./ProjectStore";

export interface PanelState {
    panels: Array<MeasuringPointPanel>;
    isFetching: boolean;
}

export interface PanelGetters {
    getPanel: (state: PanelState) => (measuringPointId: number) => Promise<MeasuringPointPanel>;
    getPanelByUrl: (_: PanelState) => (url: string) => Promise<MeasuringPointPanel>;
}

export interface PanelActions {
    fetchPanels: () => Promise<void>;
}

const _fetchPanels = async (startPage: number, projectId: number) => {
    /**
     * fetch all Panels and update state
     *
     * @param  { number }  starting page number
     * @param  { number }  id of the current project
     */
    const apiStore = useApiStore();
    const panelStore = usePanelStore();

    panelStore.isFetching = true;

    let page: number = startPage;
    let newPanels: Array<MeasuringPointPanel> = [];

    while (true) {
        const data: ListResponse<MeasuringPointPanel> | null = await apiStore.meteringApi
            .listPanels({
                page,
                pageSize: 100,
                projectId,
            })
            .catch((err: unknown) => {
                console.error(err);
                newPanels = [];
                return null;
            });

        if (!data) break;
        newPanels = newPanels.concat(data.results);
        if (!data.next) break;
        page += 1;
    }

    panelStore.isFetching = false;
    panelStore.panels = newPanels;
};

const usePanelStore: StoreDefinition<"panelStore", PanelState, PanelGetters, PanelActions> = defineStore(
    /**
     * Global Store/Context for all panels of the logged in user
     * @store
     */
    "panelStore",
    {
        state: () => {
            const projectStore = useProjectStore();

            /** fetch all panels starting with the first page */
            _fetchPanels(1, projectStore.current);

            return {
                panels: [] as Array<MeasuringPointPanel>,
                isFetching: true,
            };
        },
        getters: {
            getPanel(state: PanelState) {
                return async (measuringPointPanelId: number) => {
                    await waitUntil(() => !this.isFetching);
                    const panel: MeasuringPointPanel | undefined = state.panels.filter(
                        (mpp: MeasuringPointPanel) => mpp.id === measuringPointPanelId
                    )[0];
                    if (!panel) throw new Error(`No measuring point panel found with the id ${measuringPointPanelId}`);
                    return panel;
                };
            },
            getPanelByUrl(_: PanelState) {
                return async (url: string) => {
                    const regexp = /api\/v1\/panels\/\d+\/$/;
                    const snippetStart: number | undefined = url.match(regexp)?.index;
                    if (!snippetStart) throw new Error("Regex did not match field.");
                    const snippet: string = url.substr(snippetStart);
                    const id: number = Number(snippet.split("/")[3]);
                    const getPanel: (id: number) => Promise<MeasuringPointPanel> = this.getPanel;
                    const panel: MeasuringPointPanel = await getPanel(id);
                    return panel;
                };
            },
        },
        actions: {
            async fetchPanels() {
                const projectStore = useProjectStore();
                await _fetchPanels(1, projectStore.current);
            },
        },
    }
);

export default usePanelStore;
