import { defineStore, StoreDefinition } from "pinia";
import type { ListResponse } from "../ts/types/api_types";
import type { Subscription } from "../ts/types/subscription_types";
import { waitUntil } from "../utils/wait";
import useApiStore from "./ApiStore";
import useProjectStore from "./ProjectStore";

export interface SubscriptionState {
    subscriptions: Array<Subscription>;
    isFetching: boolean;
}

// TODO getSubscription && getSubscriptionByUrl
export interface SubscriptionGetters {
    getSubscription: (state: SubscriptionState) => (subscriptionId: number) => Promise<Subscription>;
    getSubscriptionByUrl: (_: SubscriptionState) => (url: string) => Promise<Subscription>;
}

export interface SubscriptionActions {
    fetchSubscriptions: () => Promise<void>;
}

const _fetchSubscriptions = async (startPage: number, projectId: number) => {
    /**
     * fetch all Subscriptions and update state
     *
     * @param  { number }  starting page number
     * @param  { number }  id of the current project
     */
    const apiStore = useApiStore();
    const subscriptionStore = useSubscriptionStore();

    let page: number = startPage;
    let newSubscriptions: Array<Subscription> = [];

    while (true) {
        const data: ListResponse<Subscription> | null = await apiStore.accountingApi
            .listSubscriptions({
                page,
                pageSize: 100,
                projectId,
            })
            .catch((err: unknown) => {
                console.error(err);
                newSubscriptions = [];
                return null;
            });

        if (!data?.results) break;
        newSubscriptions = newSubscriptions.concat(data.results);
        if (!data.next) break;
        page += 1;
    }

    subscriptionStore.isFetching = false;
    subscriptionStore.subscriptions = newSubscriptions;
};

const useSubscriptionStore: StoreDefinition<
    "subscriptionStore",
    SubscriptionState,
    SubscriptionGetters,
    SubscriptionActions
> = defineStore(
    /**
     * Global Store/Context for all subscriptions of the logged in user
     * @store
     */
    "subscriptionStore",
    {
        state: () => {
            const projectStore = useProjectStore();

            /** fetch all subscriptions starting with the first page */
            _fetchSubscriptions(1, projectStore.current);

            return {
                subscriptions: [] as Array<Subscription>,
                isFetching: true,
            };
        },
        getters: {
            getSubscription(state: SubscriptionState) {
                return async (subscriptionId: number) => {
                    await waitUntil(() => !this.isFetching);
                    const subscriptions = state.subscriptions.filter((sub) => sub.id === subscriptionId);
                    const subscription = subscriptions[0];
                    return subscription;
                };
            },
            getSubscriptionByUrl(_: SubscriptionState) {
                return async (url: string) => {
                    const regexp = /api\/v1\/subscriptions\/\d+\/$/;
                    const snippetStart: number | undefined = url.match(regexp)?.index;
                    if (!snippetStart) throw new Error("Regex did not match field.");
                    const snippet: string = url.substr(snippetStart);
                    const id: number = Number(snippet.split("/")[3]);
                    const subscription: Subscription = await this.getSubscription(id);
                    return subscription;
                };
            },
        },
        actions: {
            async fetchSubscriptions() {
                const projectStore = useProjectStore();
                await _fetchSubscriptions(1, projectStore.current);
            },
        },
    }
);

export default useSubscriptionStore;
