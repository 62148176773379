import { Customer } from "../ts/types/customer_types";
import type { LevyType } from "../ts/types/levy_types";

export function levyTypeDisplay(levyType: LevyType): string {
    /**
     * Convert levyType into a human-readable string format
     *
     * @param    { LevyType } levyType
     *
     * @returns  { string }  levyType as string
     */
    switch (levyType) {
        case "STROMSTEUER":
            return "Stromsteuer";
        case "EEG_UMLAGE":
            return "EEG-Umlage";
        case "KONZESSIONSABGABE":
            return "Konzessionsabgabe";
        case "KWK_UMLAGE":
            return "KWK-Umlage";
        case "PAR_19_STROMNEV_UMLAGE":
            return "§ 19 StromNEV-Umlage";
        case "OFFSHORE_NETZUMLAGE":
            return "Offshore-Netzumlage";
        case "UMLAGE_ABSCHALTBARE_LASTEN":
            return "Umlage für abschaltbare Lasten";
        case "NETZENTGELTE":
            return "Netzentgelte";
        default:
            return "undefined";
    }
}

export function formatDate(datestring: Date | string, options?: any): string {
    /**
     * Format date-string "YYYY-MM-DD" into german date format
     *
     * @params  { string } datestring
     * @params  { any }    options
     *
     * @example
     * // returns "1. Juli 2021"
     * formatDate("2021-06-01")
     *
     * @returns  { string }  formatted date string
     */
    const opt = options || {
        day: "numeric",
        month: "long",
        year: "numeric",
    };
    return new Intl.DateTimeFormat("de-DE", opt).format(new Date(datestring));
}

export function formatDatestring(date: Date): string {
    /**
     * format Date object into a string in the format of YYYY-MM-DD
     *
     * @param  { Date }  date
     *
     * @returns  { string }  "YYYY-MM-DD"
     */
    const iso: string = date.toISOString();
    const datestring: string | undefined = iso.split("T")[0];
    if (!datestring) return "";
    return datestring;
}

export function formatNumber(i: number | string, decimalPlaces: number): string {
    /**
     * Format a number into a decimal number string
     *
     * @params  { number } i
     * @params  { number } decimalPlaces
     *
     * @example
     * // returns "420,00"
     * formatNumber(420, 0)
     *
     * @returns  { string }  formatted number
     */
    const number = Number(i);
    return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    }).format(number);
}

export function billingNameShort(customer: Customer): string {
    /**
     * Return either customers fullname or company name
     * JS version of the acccounting/models.py's billing_name_short fn
     *
     * @params  { Customer} customer
     *
     * @returns  { string }  billing name
     */
    if (customer.company) return customer.company;
    return `${customer.first_name} ${customer.last_name}`;
}
