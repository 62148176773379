import axios, { AxiosResponse } from "axios";
import { defineStore, StoreDefinition } from "pinia";
import type { ListResponse } from "../ts/types/api_types";
import type { Customer } from "../ts/types/customer_types";
import type { Position, Transaction } from "../ts/types/transaction_types";
import { waitUntil } from "../utils/wait";
import useApiStore from "./ApiStore";
import useCustomerStore from "./CustomerStore";
import useProjectStore from "./ProjectStore";

export interface TransactionState {
    transactions: Array<Transaction>;
    isFetching: boolean;
}

export interface TransactionGetters {
    getTransaction: (state: TransactionState) => (transactionId: number) => Promise<Transaction>;
    getTransactionByUrl: (state: TransactionState) => (url: string) => Promise<Transaction>;
    pendingTransactions: () => Array<Transaction>;
    settledTransactions: () => Array<Transaction>;
    positions: () => Array<Position>;
    pendingPositions: () => Array<Position>;
    settledPositions: () => Array<Position>;
}

export interface TransactionActions {
    fetchTransactions: () => Promise<void>;
}

const _fetchTransactions = async (startPage: number, projectId: number) => {
    /**
     * fetch all Transactions and update state
     *
     * @param  { number }  starting page number
     * @param  { number }  id of the current project
     */
    const apiStore = useApiStore();
    const transactionStore = useTransactionStore();

    let page: number = startPage;
    let newTransactions: Array<Transaction> = [];

    while (true) {
        const response: AxiosResponse<ListResponse<Transaction>> = await axios.get(
            apiStore.defaultClient.basePath + "/api/v1/transactions",
            {
                params: {
                    page: page,
                    project__id: projectId,
                },
            }
        );
        const data: ListResponse<Transaction> | null = response.data || null;

        if (!data) break;
        newTransactions = newTransactions.concat(data.results);
        if (!data.next) break;
        page += 1;
    }

    transactionStore.isFetching = false;
    transactionStore.transactions = newTransactions;
};

const useTransactionStore: StoreDefinition<
    "transactionStore",
    TransactionState,
    TransactionGetters,
    TransactionActions
> = defineStore(
    /**
     * Global Store/Context for all transactions of the logged in user
     */
    "transactionStore",
    {
        state: () => {
            const projectStore = useProjectStore();

            /** fetch all transactions starting with the first page */
            _fetchTransactions(1, projectStore.current);

            return {
                transactions: [] as Array<Transaction>,
                isFetching: true,
            };
        },
        getters: {
            getTransaction(state: TransactionState) {
                return async (transactionId: number) => {
                    await waitUntil(() => !this.isFetching);
                    const transaction: Transaction | undefined = state.transactions.filter(
                        (t: Transaction) => t.id === transactionId
                    )[0];
                    if (!transaction) throw new Error(`No transaction found with the id ${transactionId}`);
                    return transaction;
                };
            },
            getTransactionByUrl(_: TransactionState) {
                return async (url: string) => {
                    const regexp = /api\/v1\/transactions\/\d+\/$/;
                    const snippetStart: number | undefined = url.match(regexp)?.index;
                    if (!snippetStart) throw new Error("Regex did not match field.");
                    const snippet: string = url.substr(snippetStart);
                    const id: number = Number(snippet.split("/")[3]);
                    const getTransaction: (id: number) => Promise<Transaction> = this.getTransaction;
                    const transaction: Transaction = await getTransaction(id);
                    return transaction;
                };
            },
            pendingTransactions(state: TransactionState): Array<Transaction> {
                return state.transactions.filter((t: Transaction) => t.state === "pending");
            },
            settledTransactions(state: TransactionState): Array<Transaction> {
                return state.transactions.filter((t: Transaction) => t.state === "settled");
            },
            positions(state: TransactionState): Array<Position> {
                const customerStore = useCustomerStore();
                const customers: Array<Customer> = customerStore.customers;

                const matched: Array<Position> = [];
                for (const transaction of state.transactions) {
                    for (const customer of customers) {
                        if (transaction.customer?.includes(`/${customer.id}/`)) {
                            matched.push([customer, transaction]);
                            break;
                        }
                    }
                }
                return matched;
            },
            pendingPositions(_: TransactionState): Array<Position> {
                return this.positions.filter((p: Position) => p[1].state === "pending");
            },
            settledPositions(_: TransactionState): Array<Position> {
                return this.positions.filter((p: Position) => p[1].state === "settled");
            },
        },
        actions: {
            async fetchTransactions() {
                const projectStore = useProjectStore();
                await _fetchTransactions(1, projectStore.current);
            },
        },
    }
);

export default useTransactionStore;
