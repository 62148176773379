import { defineStore, StoreDefinition } from "pinia";
import type { ListResponse } from "../ts/types/api_types";
import type { ResidualPowerPlan } from "../ts/types/residualpowerplan_types";
import { waitUntil } from "../utils/wait";
import useApiStore from "./ApiStore";
import useProjectStore from "./ProjectStore";

export interface ResidualPowerPlanState {
    residualPowerPlans: Array<ResidualPowerPlan>;
    isFetching: boolean;
}

export interface ResidualPowerPlanGetters {
    getResidualPowerPlan: (
        state: ResidualPowerPlanState
    ) => (residualPowerPlanId: number) => Promise<ResidualPowerPlan>;
    getResidualPowerPlanByUrl: (_: ResidualPowerPlanState) => (url: string) => Promise<ResidualPowerPlan>;
}

export interface ResidualPowerPlanActions {
    fetchResidualPowerPlans: () => Promise<void>;
}

const _fetchResidualPowerPlans = async (startPage: number, projectId: number) => {
    /**
     * fetch all Residualpowerplans and update state
     *
     * @param  { number }  starting page number
     * @param  { number }  id of the current project
     */
    const apiStore = useApiStore();
    const residualPowerPlanStore = useResidualPowerPlanStore();

    residualPowerPlanStore.isFetching = true;

    let page: number = startPage;
    let newResidualPowerPlans: Array<ResidualPowerPlan> = [];

    while (true) {
        const data: ListResponse<ResidualPowerPlan> | null = await apiStore.accountingApi
            .listResidualPowerPlans({
                page,
                pageSize: 100,
                projectId,
            })
            .catch((err: unknown) => {
                console.error(err);
                newResidualPowerPlans = [];
                return null;
            });

        if (!data) break;
        newResidualPowerPlans = newResidualPowerPlans.concat(data.results);
        if (!data.next) break;
        page += 1;
    }

    residualPowerPlanStore.isFetching = false;
    residualPowerPlanStore.residualPowerPlans = newResidualPowerPlans;
};

const useResidualPowerPlanStore: StoreDefinition<
    "residualPowerPlanStore",
    ResidualPowerPlanState,
    ResidualPowerPlanGetters,
    ResidualPowerPlanActions
> = defineStore(
    /**
     * Global Store/Context for all residualpowerplans of the logged in user
     * @store
     */
    "residualPowerPlanStore",
    {
        state: () => {
            const projectStore = useProjectStore();

            /** fetch all residualpowerplans starting with the first page */
            _fetchResidualPowerPlans(1, projectStore.current);

            return {
                residualPowerPlans: [] as Array<ResidualPowerPlan>,
                isFetching: true,
            };
        },
        getters: {
            getResidualPowerPlan(state: ResidualPowerPlanState) {
                return async (residualPowerPlanId: number) => {
                    await waitUntil(() => !this.isFetching);
                    const residualPowerPlan: ResidualPowerPlan | undefined = state.residualPowerPlans.filter(
                        (rpp: ResidualPowerPlan) => rpp.id === residualPowerPlanId
                    )[0];
                    if (!residualPowerPlan)
                        throw new Error(`No residual power plan found with the id ${residualPowerPlanId}`);
                    return residualPowerPlan;
                };
            },
            getResidualPowerPlanByUrl(_: ResidualPowerPlanState) {
                return async (url: string) => {
                    const regexp = /api\/v1\/residualpowerplans\/\d+\/$/;
                    const snippetStart: number | undefined = url.match(regexp)?.index;
                    if (!snippetStart) throw new Error("Regex did not match field.");
                    const snippet: string = url.substr(snippetStart);
                    const id: number = Number(snippet.split("/")[3]);
                    const getResPlan: (id: number) => Promise<ResidualPowerPlan> = this.getResidualPowerPlan;
                    const resplan: ResidualPowerPlan = await getResPlan(id);
                    return resplan;
                };
            },
        },
        actions: {
            async fetchResidualPowerPlans() {
                const projectStore = useProjectStore();
                await _fetchResidualPowerPlans(1, projectStore.current);
            },
        },
    }
);

export default useResidualPowerPlanStore;
