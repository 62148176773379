import { defineStore, StoreDefinition } from "pinia";
import type { ListResponse } from "../ts/types/api_types";
import type { Plan } from "../ts/types/plan_types";
import { waitUntil } from "../utils/wait";
import useApiStore from "./ApiStore";
import useProjectStore from "./ProjectStore";
export interface PlanState {
    plans: Array<Plan>;
    isFetching: boolean;
}

export interface PlanGetters {
    getPlan: (state: PlanState) => (planId: number) => Promise<Plan>;
    getPlanByUrl: (_: PlanState) => (url: string) => Promise<Plan>;
}

export interface PlanActions {
    fetchPlans: () => Promise<void>;
}

const _fetchPlans = async (startPage: number, projectId: number) => {
    /**
     * fetch all Plans and update state
     *
     * @param  { number }  starting page number
     * @param  { number }  id of the current project
     */
    const apiStore = useApiStore();
    const planStore = usePlanStore();

    planStore.isFetching = true;

    let page: number = startPage;
    let newPlans: Array<Plan> = [];

    while (true) {
        const data: ListResponse<Plan> | null = await apiStore.accountingApi
            .listPlans({
                page,
                pageSize: 100,
                projectId,
            })
            .catch((err: unknown) => {
                console.error(err);
                newPlans = [];
                return null;
            });

        if (!data) break;
        newPlans = newPlans.concat(data.results);
        if (!data.next) break;
        page += 1;
    }

    planStore.isFetching = false;
    planStore.plans = newPlans;
};

const usePlanStore: StoreDefinition<"planStore", PlanState, PlanGetters, PlanActions> = defineStore(
    /**
     * Global Store/Context for all plans of the logged in user
     * @store
     */
    "planStore",
    {
        state: () => {
            const projectStore = useProjectStore();

            /** fetch all plans starting with the first page */
            _fetchPlans(1, projectStore.current);

            return {
                plans: [] as Array<Plan>,
                isFetching: true,
            };
        },
        getters: {
            getPlan(state: PlanState) {
                return async (planId: number) => {
                    await waitUntil(() => !this.isFetching);
                    const plan: Plan | undefined = state.plans.filter((plan: Plan) => plan.id === planId)[0];
                    if (!plan) throw new Error(`No plan found with the id ${planId}`);
                    return plan;
                };
            },
            getPlanByUrl(_: PlanState) {
                return async (url: string) => {
                    const regexp = /api\/v1\/plans\/\d+\/$/;
                    const snippetStart: number | undefined = url.match(regexp)?.index;
                    if (!snippetStart) throw new Error("Regex did not match field.");
                    const snippet: string = url.substr(snippetStart);
                    const id: number = Number(snippet.split("/")[3]);
                    const getPlan: (id: number) => Promise<Plan> = this.getPlan;
                    const plan: Plan = await getPlan(id);
                    return plan;
                };
            },
        },
        actions: {
            async fetchPlans() {
                const projectStore = useProjectStore();
                await _fetchPlans(1, projectStore.current);
            },
        },
    }
);

export default usePlanStore;
