export function waitUntil(predicate: () => boolean): Promise<any> {
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            if (predicate()) {
                clearInterval(interval);
                resolve({});
            }
        }, 100);
    });
}
